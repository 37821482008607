import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import NarrativeBookView from '@/views/NarrativebookView.vue'

import HomeContent from '@/components/HomeContent.vue'
import AboutContent from '@/components/AboutContent.vue'
import ServiceContent from '@/components/ServiceContent.vue'
import PrivacyContent from '@/components/PrivacyContent.vue'
import CocoChannel from '@/components/CocoChannel.vue'
import ForeignRecruitment from '@/components/ForeignRecruitment.vue'
import SocialMediaPolicy from '@/components/SocialMediaPolicy.vue'

import NarrativebookContent from '@/components/narrativebook/NarrativebookContent.vue'
import CareContent from '@/components/narrativebook/CareContent.vue'
import KidsContent from '@/components/narrativebook/KidsContent.vue'
import OnlineContent from '@/components/narrativebook/OnlineContent.vue'
import ManualMenu from '@/components/narrativebook/ManualMenu.vue'
import ParticipantList from '@/components/narrativebook/ParticipantList.vue'
import ContactPage from '@/components/narrativebook/ContactsPage.vue'

// articles
import MovieListPage from '@/components/narrativebook/ArticleContents/MovieListPage.vue'

import TeaserPage from '@/views/TeaserPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '',
        name: 'home_content',
        component: HomeContent,
        meta: {
          page_title: '',
          page_description: '一般社団法人地域医療介護連携推進機構（略称：COCO（ココ））は、そこに住む全ての方が、自分らしい暮らしを選択し、続けられるように、地域の包括的な支援・サービス提供体制を支援する活動を行います。'
        }
      },
      {
        path: 'about',
        name: 'about',
        component: AboutContent,
        meta: {
          page_title: 'COCOについて',
          page_description: '一般社団法人地域医療介護連携推進機構（略称：COCO（ココ））は、そこに住む全ての方が、自分らしい暮らしを選択し、続けられるように、地域の包括的な支援・サービス提供体制を支援する活動を行います。'
        }
      },
      {
        path: 'service',
        name: 'service',
        component: ServiceContent,
        meta: {
          page_title: 'サービス:',
          page_description: '一般社団法人地域医療介護連携推進機構（略称：COCO（ココ））は、そこに住む全ての方が、自分らしい暮らしを選択し、続けられるように、地域の包括的な支援・サービス提供体制を支援する活動を行います。'
        }
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: PrivacyContent,
        meta: {
          page_title: 'プライバシーポリシー:',
          page_description: '一般社団法人地域医療介護連携推進機構（略称：COCO（ココ））は、そこに住む全ての方が、自分らしい暮らしを選択し、続けられるように、地域の包括的な支援・サービス提供体制を支援する活動を行います。'
        }
      },
      {
        path: 'coco_channel',
        name: 'coco_channel',
        component: CocoChannel,
        meta: {
          page_title: 'COCOチャンネル:',
          page_description: 'COCOチャンネル：一般社団法人地域医療介護連携推進機構（略称：COCO（ココ））の提供する動画コンテンツ。'
        }
      },
      {
        path: 'jinzai',
        name: 'jinzai',
        component: ForeignRecruitment,
        meta: {
          page_title: 'COCOチャンネル:',
          page_description: 'COCOチャンネル：一般社団法人地域医療介護連携推進機構（略称：COCO（ココ））の提供する動画コンテンツ。'
        }
      }
    ]
  },
  {
    path: '/narrativebook',
    redirect: { path: '/narrativebook_akita' }
  },
  {
    path: '/narrativebook_akita',
    name: 'narrativebook_akita',
    component: NarrativeBookView,
    children: [
      {
        path: ':content*',
        name: 'narrativebook_content',
        component: NarrativebookContent,
        props: true,
        meta: {
          page_title: 'ナラティブブック秋田:',
          page_description: 'ナラティブブック秋田は、情報共有ツールである「ナラティブブック」を利用した、地域に暮らす人々と、それを見守る人をつなぐ、情報共有の仕組みです。'
        }
      },
      {
        path: 'care_narrative/:content*',
        name: 'care_narrative',
        component: CareContent,
        props: true,
        meta: {
          page_title: 'ケアナラティブ秋田:',
          page_description: 'ケアナラティブ秋田は、ナラティブブック秋田の取り組みのうち、 地域包括ケア、特に訪問診療やACPなどにかかわる方々を 中心とした見守りの取り組みです。'
        }
      },
      {
        path: 'kids_narrative/:content*',
        name: 'kids_narrative',
        component: KidsContent,
        props: true,
        meta: {
          page_title: 'キッズナラティブ秋田:',
          page_description: 'キッズナラティブ秋田は、ナラティブブック秋田の取り組みのうち、医療的ケア児を中心とした、新しい見守りの活動です。'
        }
      },
      {
        path: 'online_medical',
        name: 'online_medical',
        component: OnlineContent,
        meta: {
          page_title: 'オンライン診療:',
          page_description: 'ナラティブブック秋田は、情報共有ツールである「ナラティブブック」を利用した、地域に暮らす人々と、それを見守る人をつなぐ、情報共有の仕組みです。'
        }
      },
      {
        path: 'participant_list',
        name: 'participant_list',
        component: ParticipantList,
        meta: {
          page_title: '参加施設一覧(ナラティブブック秋田):',
          page_description: 'ナラティブブック秋田は、情報共有ツールである「ナラティブブック」を利用した、地域に暮らす人々と、それを見守る人をつなぐ、情報共有の仕組みです。'
        }
      },
      {
        path: 'manual_menu',
        name: 'manual_menu',
        component: ManualMenu,
        meta: {
          page_title: 'マニュアル一覧(ナラティブブック秋田):',
          page_description: 'ナラティブブック秋田は、情報共有ツールである「ナラティブブック」を利用した、地域に暮らす人々と、それを見守る人をつなぐ、情報共有の仕組みです。'
        }
      },
      {
        path: 'social_media_policy',
        name: 'social_media_policy',
        component: SocialMediaPolicy,
        meta: {
          page_title: 'ソーシャルメディアポリシー',
          page_description: '一般社団法人地域医療介護連携推進機構（略称：COCO（ココ））は、そこに住む全ての方が、自分らしい暮らしを選択し、続けられるように、地域の包括的な支援・サービス提供体制を支援する活動を行います。'
        }
      },
      {
        path: 'articles/movie_list/:pagename',
        name: 'articles_movie_list',
        component: MovieListPage,
        meta: {
          page_title: '',
          page_description: '',
          props: true
        }
      },
      {
        path: 'contacts/:style*',
        name: "contacts",
        component: ContactPage,
        props: true,
        meta: {
          page_title: 'お問い合わせ',
          page_description: '',
        }
      }
    ]
  },
  {
    path: '/teaser',
    name: 'teaser-page',
    component: TeaserPage
  },
  {
    path: '/index.html',
    redirect: '/'
  },
  {
    path: '/narrativebook_akita.html',
    redirect: '/narrativebook_akita'
  },
  {
    path: '/about.html',
    redirect: '/about'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.afterEach((to) => {
  if (to.meta.page_title) {
    document.title = to.meta.page_title + process.env.VUE_APP_PAGE_TITLE
  }
  if (to.meta.page_description) {
    document.querySelector("meta[name='description']").setAttribute('content', to.meta.page_description)
  }
})

export default router
