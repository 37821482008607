<template>
  <main>
    <article>
      <section class="main-navigation">
        <narrative-akita-main-navigation
          :navigationContents="slideContents"
          v-on:contentSelectEvent="slideContentSelected"
          />
      </section>
      <section class="hero" v-show="currentContent == 'main'">
        <div class="hero-caption">
          <h2><span>みんなで綴り</span><span>未来へつなげる</span><span>ものがたり</span></h2>
          <span>キッズナラティブ秋田は、</span><br/>
          <span>多くの人のサポートが</span><span>必要なお子さんと、</span><br/>
          <span>それをささえる人たちを繋ぎ、</span><br/>
          <span>ともに歩むお手伝いをします。</span>
          <img class="main_image" src="/img/kids_main_img.png" />
        </div>
      </section>
      <section class="what-kids-nb-akita" v-show="currentContent == 'main'">
        <div class="content">
          <h2 class="title"><img src="/img/kids_nbakita_title_what.png" alt="キッズナラティブ秋田とは" /></h2>
          <div class="inside-content">
            <img class="kids-narrative-fig1" src="/img/kids-narrative-fig1.png" alt="キッズナラティブを使う親子"/>
            <p>
              キッズナラティブ秋田は、ナラティブブック秋田の取り組みのうち、医療的ケア児を中心とした、新しい見守りの活動です。<br/>
              ちいさなお子さんは自分でスマホやパソコンを利用できないことが多いため、具体的な利用の主体は保護者になります。<br/>
              保護者が主体となってお子さんのアカウントを作成・管理し、そこにご家族や医療・福祉・介護・教育関係者が日々の情報を投稿することによって、本人や保護者の想いをささえる取り組みです。
            </p>
          </div>
        </div>
        <div class="figure">
          <img class="kids-narrative-fig2" src="/img/kids_main_fig.png" alt="キッズナラティブ秋田でつながる人々"/>
        </div>
        <h3>キッズナラティブ秋田でできること</h3>
        <div class="usages">
          <div class="usage">
            <div class="title">医療・介護スタッフと情報共有ができる</div>
            <div class="caption">お子さんに関わる人たちと、保護者あるいはお子さん自身を交えてコミュニケーションが取れます。</div>
            <div class="caption">みまもっている家族や医療・福祉・介護・教育関係者を一覧で確認でき、連絡を取ることができます。</div>
            <img class="fig" src="/img/kids_usage1.png" />
          </div>
          <div class="usage">
            <div class="title">お子さんのことで多職種が共有しておきたいこと</div>
            <div class="caption">症状のことや体調のこと、連絡事項等を多職種で共有します。</div>
            <div class="caption">必要な情報は掲示板にまとめることができます。</div>
            <img class="fig" src="/img/kids_usage2.png" />
          </div>
          <div class="usage">
            <div class="title">お子さんのご家族や大切な関係者も参加</div>
            <div class="caption">遠くにいたり、なかなか連絡を取りにくい家族や特に親しくしている大切な関係者と連絡が取れ、お子さんに関わる日常が見えるようになります。</div>
            <img class="fig" src="/img/kids_usage3.png" />
          </div>
          <div class="usage">
            <div class="title">お子さんと保護者の生活や想いを共有する</div>
            <div class="caption">お子さんそれぞれに合った医療や生活支援のために、お子さんと保護者の想いや夢、希望、治療の目標についてみんなで書き込み、目標を共有できます。</div>
            <div class="caption">みまもっている家族や医療・介護スタッフを一覧で見ることができます。</div>
            <img class="fig" src="/img/kids_usage4.png" />
          </div>
        </div>
      </section>
      <section v-show="currentContent == 'main'">
        <kids-how-to-start-content-block />
      </section>
      <section v-show="currentContent == 'main'">
        <kids-participant-list-content-block />
      </section>
      <!-- <section v-show="currentContent == 'main'">
        <how-to-start-content-block
          :category="'kids'"
          />
      </section> -->
      <section v-show="currentContent == 'documents'">
        <document-content-block
          :category="'kids'"
          />
      </section>
      <section v-show="currentContent == 'documents'">
        <manual-movie-content-block
          :category="'kids'"
          />
      </section>
      <section v-show="currentContent == 'signin'">
        <phone-app-content-block
          :category="'kids'"
          />
      </section>
      <section v-show="currentContent == 'event'">
        <event-content-block
          :category="'kids'"
          />
      </section>
      <section v-show="currentContent == 'orglist'">
        <participant-list-content-block
          :category="'kids'"
          />
      </section>
      <section v-if="currentContent == 'youtube'">
        <youtube-content-block
          :category="'kids'"
          />
      </section>
    </article>
  </main>
</template>

<script>
import NarrativeAkitaMainNavigation from '@/components/narrativebook/NarrativeAkitaMainNavigation.vue'
import ManualMovieContentBlock from '@/components/narrativebook/commonContent/ManualMovieContentBlock.vue'
import DocumentContentBlock from '@/components/narrativebook/commonContent/DocumentContentBlock.vue'
import EventContentBlock from '@/components/narrativebook/commonContent/EventContentBlock.vue'
// import HowToStartContentBlock from '@/components/narrativebook/commonContent/HowToStartContentBlock.vue'
import ParticipantListContentBlock from '@/components/narrativebook/commonContent/ParticipantListContentBlock.vue'
import YoutubeContentBlock from '@/components/narrativebook/commonContent/YoutubeContentBlock.vue'
import PhoneAppContentBlock from '@/components/narrativebook/commonContent/PhoneAppContentBlock.vue'
import KidsHowToStartContentBlock from '@/components/narrativebook/commonContent/KidsHowToStartContentBlock.vue'
import KidsParticipantListContentBlock from '@/components/narrativebook/commonContent/KidsParticipantListContentBlock.vue'

export default {
  name: 'KidsContent',

  components: {
    NarrativeAkitaMainNavigation,
    DocumentContentBlock,
    ManualMovieContentBlock,
    EventContentBlock,
    // HowToStartContentBlock,
    ParticipantListContentBlock,
    YoutubeContentBlock,
    PhoneAppContentBlock,
    KidsHowToStartContentBlock,
    KidsParticipantListContentBlock
  },

  watch: {
    '$route': { handler: 'switchContentByPath', immediate: true },
  },

  data () {
    return {
      currentContent: 'main',
      slideContents: [
      {
          title: 'キッズナラティブ秋田とは',
          img: '/img/carousel_imgs/carousel_kids_top.png',
          thumbImg: '/img/carousel_imgs/carousel_kids_top.png',
          phoneImg: '/img/carousel_imgs/carousel_kids_top.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_kids_top.png',
          linkURL: 'main'
        },
        // {
        //   title: 'キッズナラティブ秋田の始め方',
        //   img: '/img/carousel_imgs/carousel_kids_start.png',
        //   thumbImg: '/img/carousel_imgs/carousel_kids_start.png',
        //   phoneImg: '/img/carousel_imgs/carousel_kids_start.png',
        //   phoneThumbImg: '/img/carousel_imgs/carousel_kids_start.png',
        //   linkURL: 'howto'
        // },
        {
          title: '資料・マニュアル',
          img: '/img/carousel_imgs/carousel_kids_docs.png',
          thumbImg: '/img/carousel_imgs/carousel_kids_docs.png',
          phoneImg: '/img/carousel_imgs/carousel_kids_docs.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_kids_docs.png',
          linkURL: 'documents'
        },
        {
          title: 'イベントスケジュール',
          img: '/img/carousel_imgs/carousel_kids_event.png',
          thumbImg: '/img/carousel_imgs/carousel_kids_event.png',
          phoneImg: '/img/carousel_imgs/carousel_kids_event.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_kids_event.png',
          linkURL: 'event'
        },
        {
          title: 'ログイン',
          img: '/img/carousel_imgs/carousel_kids_app.png',
          thumbImg: '/img/carousel_imgs/carousel_kids_app.png',
          phoneImg: '/img/carousel_imgs/carousel_kids_app.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_kids_app.png',
          linkURL: 'signin'
        },
        {
          title: '施設リスト',
          img: '/img/carousel_imgs/carousel_kids_orgs.png',
          thumbImg: '/img/carousel_imgs/carousel_kids_orgs.png',
          phoneImg: '/img/carousel_imgs/carousel_kids_orgs.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_kids_orgs.png',
          linkURL: 'orglist'
        },
        {
          title: '動画アーカイブ',
          img: '/img/carousel_imgs/carousel_kids_youtube.png',
          thumbImg: '/img/carousel_imgs/carousel_kids_youtube.png',
          phoneImg: '/img/carousel_imgs/carousel_kids_youtube.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_kids_youtube.png',
          linkURL: 'youtube'
        },
      ]
    }
  },

  methods: {
    slideContentSelected (selected) {
      console.log(selected)
      this.$router.push({path: '/narrativebook_akita/kids_narrative/' + selected })
      // this.currentContent = selected
    },

    switchContentByPath () {
      if (this.$route.params.content && this.$route.params.content[0]) {
        var content = this.slideContents.find((cont) => cont.linkURL == this.$route.params.content[0])
        console.log(content)
        if (content) {
          this.currentContent = content.linkURL
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

div.outer-frame {
  background-color: $kids-bg-color;
  background-image: url('~@/assets/css/images/kids_bg.png');
  background-repeat: repeat;
  color: $kids-text-color;
  h2 {
    margin: 0;
    text-align: center;
    color: $kids-main-color;
  }
  main {
    background-color: $kids-bg-color;
    background-image: none;
    // height: calc(100vh - 80px);
    // height: calc(100dvh - 80px);
    overflow: auto;
  }
  section.main-navigation {
    margin: 0;
    padding: 0;
  }
  section.hero {
    height: auto;
    background-image: none;
    background-color: transparent;
    padding: 0;
    .hero-title {
      height: 548px;
      background-color: $kids-main-color;
      background-image: url('~@/assets/css/images/kids_hero_bg.png');
      h1 {
        display: none;
        margin: 0;
        color: #fff;
      }
    }
    .hero-caption {
      margin: 0;
      padding-top: 30px;      
      height: 589px;
      background-color: transparent;
      background-image: url('~@/assets/css/images/kids_hero_caption_bg.png');
      color: $kids-main-color;
      text-align: center;
      font-size: 17px;
      line-height: 40px;
      letter-spacing: 0.2em;
      font-weight: 600;
      h2 {
        margin-bottom: 30px;
        padding: 25px 0;
        background-color: $kids-main-color;
        color: #fff;
        font-size: 30px;
      }
      span {
        display: inline-block;
      }
      img.main_image {
        display: none;
      }
    }
  }
  section.what-kids-nb-akita {
    h2 {
      margin: 0;
    }
    margin: 28px 0;
    padding: 0 68px;
    .content {
      padding: 65px 60px 50px;
      background-color: rgba(256,256,256,0.5);
      background-image: none;
      .inside-content {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-size: 18px;
          line-height: 33px;
        }
        img {
          margin-right: 30px;
        }
      }
    }
    .figure {
      text-align: center;
    }
    h3 {
      color: $kids-main-color;
      font-size: 22px;
      text-align: center;
    }
    .usages {
      display: flex;
      flex-wrap: wrap;
      .usage {
        margin: 10px;
        padding: 20px;
        width: calc(50% - 60px);
        border-radius: 20px;
        background-color: #eef5e0;
        @include mediaQuery('phone') {
          width: 100%;
        }
        .title {
          font-weight: bold;
          color: $kids-main-color;
        }
        .caption {
          margin: 5px 0;
          color: $kids-text-color;
          font-size: 14px;
        }
      }
    }
  }
  section {
    padding: 0 130px;
    .content {
      padding: 67px 82px;
      background-color: rgba(256,256,256,0.5);
      background-image: url('~@/assets/css/images/kids_contact_bg.png');
      background-repeat: no-repeat;
      background-position: right bottom;
      h2 {
        margin: 0;
        color: $kids-main-color;
      }
      .for-akita {
        h3 {
          margin-top: 34px;
          margin-bottom: 0;
          font-family: $bold-font-family;
          font-size: 20px;
          letter-spacing: 0.2em;
        }
        p {
          margin-top: 5px;
          margin-bottom: 0;
          font-size: 14px;
          line-height: 27px;
          span {
            display: inline-block;
          }
          .tel {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            font-size: 24px;
            line-height: 27px;
          }
        }
      }
    }
  }
  section.others {
    padding: 0 130px;
    .content {
      margin: 67px 0;
      padding: 67px 82px;
      background-color: rgba(256,256,256,0.5);
      h2 {
        margin: 0;
        color: $kids-main-color;
      }
      ul.content-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-block-start: 0;
        margin-inline-start: 0;
        li {
          .top-index {
            display: block;
            margin-top: 20px;
            padding-left: 30px;
            font-size: 20px;
            font-weight: bold;
            border-bottom: 1px solid $kids-text-color;
          }
          .caption {
            margin: 5px;
            padding: 5px;
            border: 1px solid $kids-text-color;
            border-radius: 5px;
            font-size: 14px;
          }
          ul.sub-menu {
            z-index: 1000;
            margin: 0;
            padding: 0;
            list-style-type: none;
            margin-block-start: 0;
            margin-inline-start: 0;
            &.narrative-signin {
              text-align: center;
              li {
                display: inline-block;
                margin: 5px 0;
                width: auto;
                border: 1px solid $care-text-color;
                background-color: #fff;
              }
            }
            li {
              margin-left: 30px;
              &.index {
                margin-top: 10px;
                margin-left: 10px;
              }
              &.colmn-style {
                display: flex;
                justify-content: space-evenly;
              }
              a {
                display: inline-block;
                width: 100%;
                height: 100%;
                padding: 5px 10px;
                text-decoration: none;
                color: $kids-text-color;
                &.image-link {
                  display: inline-flex;
                  align-items: center;
                }
                .file-style {
                  display: inline-block;
                  margin-right: 5px;
                  padding: 3px;
                  background-color: $kids-main-color;
                  color: #fff;
                  font-size: 10px;
                  font-weight: bold;
                }
                .jump-mark {
                  margin-left: 5px;
                  color: $kids-main-color;
                }
                .video-jump-mark {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 5px;
                  width: 20px;
                  height: 15px;
                  font-size: 10px;
                  text-align: center;
                  border-radius: 2px;
                  background-color: $kids-main-color;
                  color: #fff;
                }
                img {
                  &.google-badge {
                    height: 62px;
                  }
                  &.apple-badge {
                    padding: 10px;
                    height: 42px;
                  }
                  &.nb-icon {
                    margin-right: 5px;
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include mediaQuery('phone') {
    section.hero {
      height: auto;
      background-image: none;
      background-color: transparent;
      .hero-title {
        height: calc(100vw * 0.67);
        // background-color: $kids-main-color;
        background-image: url('~@/assets/css/images/kids_hero_bg_no_copy.png');
        background-size: cover;
        h1 {
          display: inline-block;
          img {
            width: 70%;
          }
        }
      }
      .hero-caption {
        padding-top: 10px;
        padding-bottom: 50px;
        height: auto;
        background-image: url('~@/assets/css/images/kids_hero_caption_bg_simple.png');
        background-size: cover;
        background-position: left bottom;
        font-size: 17px;
        line-height: 30px;
        img.main_image {
          display: inline-block;
          width: calc(100% - 20px);
        }
      }
    }
    section.what-kids-nb-akita {
      h2 {
        margin: 0;
      }
      margin: 10px 0;
      padding: 0 10px;
      .content {
        padding: 20px 10px;
        h2 {
          img {
            width: 100%;
          }
        }
        .inside-content {
          p {
            font-size: 16px;
            line-height: 28px;
          }
          img {
            display: none;
          }
        }
      }
      .figure {
        padding: 10px 0 0;
        img {
          width: 100%;
        }
      }
    }
    section {
      padding: 0 10px;
      .content {
        padding: 20px 10px 80px;
        background-size: 50%;
        .for-akita {
          h3 {
            font-size: 18px;
            letter-spacing: 0.1em;
          }
        }
      }
    }
    section.others {
      padding: 0 10px;
      .content {
        margin: 10px 0;
        padding: 20px 10px;
        ul.content-list {
          li {
            ul.sub-menu {
              li {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
