<template>
  <footer class="content-footer" :class="currentStyle">
    <div class="name">一般社団法人地域医療介護連携推進機構</div>
    <address>〒010-8572&nbsp;秋田県秋田市山王三丁目１番１号&nbsp;秋田県庁第二庁舎&nbsp;３階B-5&nbsp;TEL:080-4209-7029</address>
    <nav>
      <ul class="footer-menu">
        <li><router-link to="/">HOME</router-link></li>
        <li class="splitter"></li>
        <li><router-link to="/about">COCOについて</router-link></li>
        <li class="splitter"></li>
        <li><router-link to="/privacy">個人情報保護方針</router-link></li>
        <li class="splitter"></li>
        <li><router-link to="/narrativebook_akita/social_media_policy">ソーシャルメディアポリシー</router-link></li>
      </ul>
    </nav>
    <p>Copyright © 2021 Cooperation promotion organization for Community medical and care All Rights Reserved.</p>
  </footer>
</template>
<script>
  export default {
    name: 'CocoNarrativeFooter',
  
    props: {
      currentStyle: String
    },
  }
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

footer.content-footer {
  padding: 20px 0;
  border-top: 1px solid $content-text-color;
  text-align: center;
  font-size: 13px;
  address {
    font-size: 11px;
    font-style: normal;
  }
  nav {
    display: flex;
    justify-content: center;
    ul.footer-menu {
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-block-start: 0;
      margin-inline-start: 0;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 6px;
        width: 100px;
        height: 26px;
        font-size: 11px;
        text-align: center;
        &.splitter {
          width: 1px;
          height: 26px;
          margin: 0;
          border-left: 1px solid $content-text-color;
        }
        a {
          color: $content-text-color;
          text-decoration: none;
        }
      }
    }
  }
  &.nb_akita {
    background-color: $frame-background-color;
    color: $content-text-color;
  }
  &.care_narrative {
    background-color: $frame-background-color;
    color: $care-text-color;
    border-top: 1px solid $care-text-color;
    nav {
      ul.footer-menu {
        li {
          &.splitter {
            border-left: 1px solid $care-text-color;
          }
          a {
            color: $care-text-color;
          }
        }
      }
    }
  }
  &.kids_narrative {
    background-color: $kids-bg-color;
    color: $kids-text-color;
    border-top: 1px solid $kids-text-color;
    nav {
      ul.footer-menu {
        li {
          &.splitter {
            border-left: 1px solid $kids-text-color;
          }
          a {
            color: $kids-text-color;
          }
        }
      }
    }
  }
  &.online {
    background-color: $online-bg-color;
    color: $online-main-color;
    border-top: 1px solid $online-main-color;
    nav {
      ul.footer-menu {
        li {
          &.splitter {
            border-left: 1px solid $online-main-color;
          }
          a {
            color: $online-main-color;
          }
        }
      }
    }
  }
}

</style>  