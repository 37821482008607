<template>
  <div class="narrative-akita-main-navigation">
    <div class="current-content">
      <div class="display-content" v-for="(content, index) in navigationContents" :key="'nctitle' + index" v-show="currentContent === content.linkURL">
        <img :src="content.img" :alt="content.title" />
        <h2 class="title">{{ content.title }}</h2>
      </div>
    </div>
    <div class="navigation-panels">
      <div class="navigation-panel" v-for="(content, index) in navigationContents" :key="'navigationlist' + index" @click="jumpToContent(content)">
        <img :src="content.thumbImg" :alt="content.title"/>
        <span class="title">{{ content.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NarrativeAkitaMainNavigation',

  props: {
    navigationContents: Array
  },

  data () {
    return {
      currentContent: 'main'
    }
  },

  methods: {
    jumpToContent (content) {
      this.currentContent = content.linkURL
      this.$emit('contentSelectEvent', content.linkURL)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
.narrative-akita-main-navigation {
.display-content {
  position:relative;
  min-height: 256px;
  img {
    height: 256px;
    width: 1024px;
    &.pc {
      display: inline;
    }
    &.phone {
      display: none;
    }
  }
  .title {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0px 0px 5px #000;
  }
  @include mediaQuery('phone') {
    min-height: calc(100vw / 4);
    img {
      height: calc(100vw / 4);
      width: 100vw;
      &.pc {
        display: none;
      }
      &.phone {
        display: inline;
      }
    }
  }
}
.navigation-panels {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .navigation-panel {
    cursor: pointer;
    margin: 10px 0;
    position: relative;
    height: 75px;
    width: calc((100% - 50px) / 3);
    img {
      cursor: pointer;
      // height: 75px;
      width: 100%;
    }
    .title {
      position: absolute;
      bottom: 2px;
      left: 5px;
      font-size: 20px;
      color: #fff;
      text-shadow: 0px 0px 5px #000;
    }
    @include mediaQuery('phone') {
      // display: none;
      width: calc((100% - 15px) / 2);
      margin: 5px 0;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
      }
      .title {
        font-size: 16px;
      }
    }
  }
}

}
</style>