<template>
  <header class="narrativebook-akita-navigation">
    <div class="top-bar" :class="currentStyle">
      <div class="header-logo">
        <img alt="ナラティブブック秋田ロゴマーク" class="header-logo-img" src="/img/narrativebook_akita_header_logo.png" v-if="currentStyle === 'nb_akita'" />
        <img alt="ケアナラティブ秋田ロゴマーク" class="header-logo-img" src="/img/care_narrative_akita_header_logo.png" v-if="currentStyle === 'care_narrative'" />
        <img alt="キッズナラティブ秋田ロゴマーク" class="header-logo-img" src="/img/kids_narrative_akita_header_logo.png" v-if="currentStyle === 'kids_narrative'" />
        <img alt="ナラティブブック秋田ロゴマーク" class="header-logo-img-small" src="/img/narrativebook_akita_header_logo_no_text.png" v-if="currentStyle === 'nb_akita'" />
        <img alt="ケアナラティブ秋田ロゴマーク" class="header-logo-img-small" src="/img/care_narrative_akita_header_logo_no_text.png" v-if="currentStyle === 'care_narrative'" />
        <img alt="キッズナラティブ秋田ロゴマーク" class="header-logo-img-small" src="/img/kids_narrative_akita_header_logo_no_text.png" v-if="currentStyle === 'kids_narrative'" />
        <h1 v-if="currentStyle === 'online'"><span>秋田県オンライン診療</span><span>実証事業</span></h1>
      </div>
      <div class="left-navigation-items">
        <nav>
          <img class="navigation-menu" src="/img/menu_icon.png" @click="openMenu" />
          <ul class="header-menu" :class="{open: displayMenu}">
            <li :class="{active: currentPath === '/narrativebook_akita'}"><a @click="jumpTo('/narrativebook_akita')" >ナラティブブック秋田</a></li>
            <li class="splitter"></li>
            <li :class="{active: currentPath === '/narrativebook_akita/care_narrative'}"><a @click="jumpTo('/narrativebook_akita/care_narrative')" >ケアナラティブ秋田</a></li>
            <li class="splitter"></li>
            <li :class="{active: currentPath === '/narrativebook_akita/kids_narrative'}"><a @click="jumpTo('/narrativebook_akita/kids_narrative')" >キッズナラティブ秋田</a></li>
            <li class="splitter"></li>
            <li :class="{active: currentPath === '/narrativebook_akita/online_medical'}"><a @click="jumpTo('/narrativebook_akita/online_medical')" >オンライン診療</a></li>
            <li class="splitter"></li>
            <li class="twitter">
              <a href="https://twitter.com/narrativeakita?s=20&t=vM8ZtiLKeIBL4PFAmo3ohA" target="_blank">
                <svg viewBox="0 0 1200 1227" xmlns="http://www.w3.org/2000/svg">
                  <path d="m714.163 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.2396h162.604l304.797 435.9906 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z"/>
                </svg>
                <!-- <img class="twitter-icon" src="/img/2021TwitterLogoBlue.png" /> -->
              </a>
            </li>
            <li class="contact"><a @click="jumpTo('/narrativebook_akita/contacts')"><img class="contact-icon" src="/img/contact_icon.svg" /></a></li>
          </ul>
        </nav>
        <div class="header-logo-sub"><router-link to="/"><img alt="COCOロゴマーク" src="/img/header_logo_sub.png" /></router-link></div>
      </div>
    </div>
  </header>
</template>

<script>
// import NarrativeAkitaSubNavigation from './NarrativeAkitaSubNavigation.vue'
// import CareSubNavigation from './CareSubNavigation.vue'
// import KidsSubNavigation from './KidsSubNavigation.vue'

export default {
  name: 'NarrativeNavigationHeader',

  components: {
    // NarrativeAkitaSubNavigation,
    // CareSubNavigation,
    // KidsSubNavigation
  },

  props: {
    currentStyle: String
  },

  data () {
    return {
      displayMenu: false
    }
  },

  computed: {
    currentPath () {
      console.log(this.$route)
      return this.$route.fullPath
    },
  },

  methods: {
    openMenu () {
      this.displayMenu = !this.displayMenu
    },

    jumpTo (path) {
      this.displayMenu = false
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

header.narrativebook-akita-navigation {
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    position: relative;
    &.nb_akita {
      background-color: $frame-background-color;
    }
    &.care_narrative {
      background-color: $frame-background-color;
    }
    &.kids_narrative {
      background-color: $kids-bg-color;
    }
    &.online {
      background-color: $online-main-color;
      color: #fff;
      h1{
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 300;
        transform: scale(1, 1.8);
        letter-spacing: .2em;
        span {
          display: inline-block;
        }
      }
      nav {
        ul.header-menu {
          li {
            &.active {
              border-bottom: 1px solid #fff;
            }
            &.splitter {
              width: 1px;
              height: 26px;
              margin: 0;
              border-left: 1px solid #fff;
            }
            a {
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
    .header-logo {
      margin-left: 40px;
      .header-logo-img {
        display: inline-block;
      }
      .header-logo-img-small {
        display: none;
      }
    }
    .left-navigation-items {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .header-logo-sub {
      margin-right: 20px; // 仮置
    }
    nav {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .navigation-menu {
        display: none;
      }
      ul.header-menu {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-block-start: 0;
        margin-inline-start: 0;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 6px;
          width: 120px;
          height: 26px;
          font-size: 11px;
          text-align: center;
          &.active {
            border-bottom: 1px solid $border-accent-color;
          }
          &.splitter {
            width: 1px;
            height: 26px;
            margin: 0;
            border-left: 1px solid $border-accent-color;
          }
          a {
            color: $content-base-color;
            text-decoration: none;
          }
          &.twitter {
            width: auto;
            padding: 0;
            img.twitter-icon {
              width: 32px;
            }
            svg {
              width: 26px;
            }
          }
          &.contact {
            margin-left: 0;
            width: auto;
            padding: 0;
            img.contact-icon {
              width: 32px;
            }
          }
        }
      }
    }
    @include mediaQuery('phone') {
      &.online {
        background-color: $online-main-color;
        color: #fff;
        h1{
          margin: 0;
          padding: 0;
          font-size: 20px;
          font-weight: 300;
          .title-wrap {
            display: inline;
          }
        }
        nav {
          ul.header-menu {
            li {
              &.active {
                border-bottom: 1px solid #fff;
              }
              &.splitter {
                width: 1px;
                height: 26px;
                margin: 0;
                border-left: 1px solid #fff;
              }
              a {
                color: #fff;
                text-decoration: none;
              }
            }
          }
        }
      }
      .header-logo {
        margin-left: 20px;
        .header-logo-img {
          display: none;
        }
        .header-logo-img-small {
          display: inline-block;
        }
      }
      .left-navigation-items {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .header-logo-sub {
        margin-right: 20px; // 仮置
      }
      nav {
        .navigation-menu {
          display: inline-block;
          margin-right: 10px;
        }
        display: flex;
        justify-content: flex-start;
        align-items: center;
        ul.header-menu {
          display: none;
          &.open {
            z-index: 1000;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 60px;
            right: 0;
            border: 1px solid #aaa;
            li {
              margin: 0;
              width: 200px;
              height: 52px;
              font-size: 16px;
              border-bottom: 1px solid #aaa;
              background-color: #fff;
              a {
                color: $content-base-color;
              }
              &.active a {
                border-bottom: 1px solid $border-accent-color;
              }
              &.splitter {
                display: none;
              }
              &.twitter {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

</style>