<template>
  <main>
    <article>
      <section class="main-navigation">
        <narrative-akita-main-navigation
          :navigationContents="slideContents"
          v-on:contentSelectEvent="slideContentSelected"
          />
      </section>
      <section class="what-care-nb-akita" v-if="currentContent == 'main'">
        <div class="content">
          <h2 class="title"><img src="/img/care_nbakita_title_what.png" alt="ケアナラティブ秋田とは" /></h2>
          <p>
            ケアナラティブ秋田は、あなたがどのように暮らしていきたいかを<br/>
            あなたを見守る人たちと共有するための仕組みです。<br/>
            あなたと、あなたを見守る人たちが<br/>
            あなたにかかわる様々なことをナラティブブックに書き込みます。<br/>
            その書き込みの積み重ねこそが、ナラティブ＝あなたのものがたりです。<br/>
            ケアナラティブ秋田は、あなたのものがたりを見守る人たちと共有し、<br/>
            あなたの望みや想いに寄り添うお手伝いをします。
          </p>
        </div>
        <div class="figure">
          <img class="care-narrative-fig1" src="/img/care_main_fig.png" alt="ケアナラティブ秋田でつながる人々"/>
        </div>
        <h3>ケアナラティブ秋田でできること</h3>
        <div class="usages">
          <div class="usage">
            <div class="title">医療・介護スタッフと情報共有ができる</div>
            <div class="caption">利用する本人に関わる人たちと、本人・家族を交えてコミュニケーションが取れます。</div>
            <div class="caption">みまもっている家族や医療・介護スタッフを一覧で見ることができます。</div>
            <img class="fig" src="/img/care_usage1.png" />
          </div>
          <div class="usage">
            <div class="title">本人のことで多職種が共有しておきたいこと</div>
            <div class="caption">症状のことや体調のこと、連絡事項等を多職種で共有します。</div>
            <div class="caption">必要な情報は掲示板にまとめることができます。</div>
            <img class="fig" src="/img/care_usage2.png" />
          </div>
          <div class="usage">
            <div class="title">本人の家族も参加</div>
            <div class="caption">遠くにいたり、帰りが遅かったりして、連絡を取りにくい家族とも連絡が取れ、本人の日常が見えるようになります。</div>
            <img class="fig" src="/img/care_usage3.png" />
          </div>
          <div class="usage">
            <div class="title">本人の生活や想いを共有する</div>
            <div class="caption">本人の望む医療・介護のために、本人の想いや夢、希望についてみんなで書き込み、目標を共有できます。</div>
            <div class="caption">みまもっている家族や医療・介護スタッフを一覧で見ることができます。</div>
            <img class="fig" src="/img/care_usage4.png" />
          </div>
        </div>
      </section>
      <section v-show="currentContent == 'main'">
        <how-to-start-content-block
          :category="'care'"
          />
      </section>
      <section v-show="currentContent == 'documents'">
        <document-content-block
          :category="'care'"
          />
      </section>
      <section v-show="currentContent == 'documents'">
        <manual-movie-content-block
          :category="'care'"
          />
      </section>
      <section v-show="currentContent == 'signin'">
        <phone-app-content-block
          :category="'care'"
          />
      </section>
      <section v-show="currentContent == 'event'">
        <event-content-block
          :category="'care'"
          />
      </section>
      <section v-show="currentContent == 'orglist'">
        <participant-list-content-block
          :category="'care'"
          />
      </section>
      <section v-if="currentContent == 'youtube'">
        <youtube-content-block
          :category="'care'"
          />
      </section>
    </article>
  </main>
</template>

<script>
import NarrativeAkitaMainNavigation from '@/components/narrativebook/NarrativeAkitaMainNavigation.vue'
import DocumentContentBlock from '@/components/narrativebook/commonContent/DocumentContentBlock.vue'
import ManualMovieContentBlock from '@/components/narrativebook/commonContent/ManualMovieContentBlock.vue'
import EventContentBlock from '@/components/narrativebook/commonContent/EventContentBlock.vue'
import HowToStartContentBlock from '@/components/narrativebook/commonContent/HowToStartContentBlock.vue'
import ParticipantListContentBlock from '@/components/narrativebook/commonContent/ParticipantListContentBlock.vue'
import YoutubeContentBlock from '@/components/narrativebook/commonContent/YoutubeContentBlock.vue'
import PhoneAppContentBlock from '@/components/narrativebook/commonContent/PhoneAppContentBlock.vue'

export default {
  name: 'CareContent',

  components: {
    NarrativeAkitaMainNavigation,
    DocumentContentBlock,
    ManualMovieContentBlock,
    EventContentBlock,
    HowToStartContentBlock,
    ParticipantListContentBlock,
    YoutubeContentBlock,
    PhoneAppContentBlock
  },

  watch: {
    '$route': { handler: 'switchContentByPath', immediate: true },
  },

  data () {
    return {
      currentContent: 'main',
      slideContents: [
      {
          title: 'ケアナラティブ秋田とは',
          img: '/img/carousel_imgs/carousel_care_top.png',
          thumbImg: '/img/carousel_imgs/carousel_care_top.png',
          phoneImg: '/img/carousel_imgs/carousel_care_top.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_care_top.png',
          linkURL: 'main'
        },
        // {
        //   title: 'ケアナラティブ秋田の始め方',
        //   img: '/img/carousel_imgs/carousel_care_start.png',
        //   thumbImg: '/img/carousel_imgs/carousel_care_start.png',
        //   phoneImg: '/img/carousel_imgs/carousel_care_start.png',
        //   phoneThumbImg: '/img/carousel_imgs/carousel_care_start.png',
        //   linkURL: 'howto'
        // },
        {
          title: '資料・マニュアル',
          img: '/img/carousel_imgs/carousel_care_docs.png',
          thumbImg: '/img/carousel_imgs/carousel_care_docs.png',
          phoneImg: '/img/carousel_imgs/carousel_care_docs.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_care_docs.png',
          linkURL: 'documents'
        },
        {
          title: 'イベントスケジュール',
          img: '/img/carousel_imgs/carousel_care_event.png',
          thumbImg: '/img/carousel_imgs/carousel_care_event.png',
          phoneImg: '/img/carousel_imgs/carousel_care_event.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_care_event.png',
          linkURL: 'event'
        },
        {
          title: 'ログイン',
          img: '/img/carousel_imgs/carousel_care_app.png',
          thumbImg: '/img/carousel_imgs/carousel_care_app.png',
          phoneImg: '/img/carousel_imgs/carousel_care_app.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_care_app.png',
          linkURL: 'signin'
        },
        {
          title: '施設リスト',
          img: '/img/carousel_imgs/carousel_care_orgs.png',
          thumbImg: '/img/carousel_imgs/carousel_care_orgs.png',
          phoneImg: '/img/carousel_imgs/carousel_care_orgs.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_care_orgs.png',
          linkURL: 'orglist'
        },
        {
          title: '動画アーカイブ',
          img: '/img/carousel_imgs/carousel_care_youtube.png',
          thumbImg: '/img/carousel_imgs/carousel_care_youtube.png',
          phoneImg: '/img/carousel_imgs/carousel_care_youtube.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_care_youtube.png',
          linkURL: 'youtube'
        },
      ]
    }
  },

  methods: {
    slideContentSelected (selected) {
      console.log(selected)
      this.$router.push({path: '/narrativebook_akita/care_narrative/' + selected })
      // this.currentContent = selected
    },

    switchContentByPath () {
      var content = this.slideContents.find((cont) => cont.linkURL == this.$route.params.content[0])
      console.log(content)
      if (content) {
        this.currentContent = content.linkURL
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

div.outer-frame {
  color: $care-text-color;
  background-color: $frame-background-color;
  main {
    // height: calc(100vh - 80px);
    // height: calc(100dvh - 80px);
    overflow: auto;
    // background-image: url('~@/assets/css/images/care_hero_caption_bg_l.png'), url('~@/assets/css/images/care_hero_caption_bg_r.png');
    background-image: none;
    background-repeat: no-repeat, no-repeat;
    background-position: left 480px, right 520px;
    background-color: $frame-background-color;
  }
  section h2.title {
    margin: 0;
  }
  section.main-navigation {
    margin: 0;
    padding: 0;
  }
  section.what-care-nb-akita {
    padding: 0 130px;
    background-image: url('~@/assets/css/images/care_what_bg.png');
    background-repeat: no-repeat;
    background-position: right 70px;
    .content {
      padding: 63px 82px;
      background-color: rgba(256,256,256,0.5);
      font-size: 18px;
      line-height: 33px;
    }
    .figure {
      padding: 68px 0;
      text-align: center;
      .care-narrative-fig1 {
        width: 100%;
      }
    }
    h3 {
      color: $care-accent-color;
      font-size: 22px;
      text-align: center;
    }
    .usages {
      display: flex;
      flex-wrap: wrap;
      .usage {
        margin: 10px;
        padding: 20px;
        width: calc(50% - 60px);
        border-radius: 20px;
        background-color: rgba(256,256,256,0.5);
        @include mediaQuery('phone') {
          width: 100%;
        }
        .title {
          font-weight: bold;
          color: $care-accent-color;
        }
        .caption {
          margin: 5px 0;
          color: $care-text-color;
          font-size: 14px;
        }
      }
    }
  }
  section {
    padding: 0 130px;
    .content {
      padding: 63px 82px;
      background-color: rgba(256,256,256,0.5);
      background-image: none;
      .for-akita {
        h3 {
          margin-top: 34px;
          margin-bottom: 0;
          font-family: $bold-font-family;
          font-size: 20px;
          letter-spacing: 0.2em;
        }
        p {
          margin-top: 5px;
          margin-bottom: 0;
          font-size: 14px;
          line-height: 27px;
          span {
              display: inline-block;
            }
          .tel {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            font-size: 24px;
            line-height: 27px;
          }
        }
      }
    }
  }
  section.others {
    padding: 0 130px;
    .content {
      margin: 67px 0;
      padding: 67px 82px;
      background-color: rgba(256,256,256,0.5);
      h2 {
        margin: 0;
        color: $care-main-color;
      }
      ul.content-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-block-start: 0;
        margin-inline-start: 0;
        li {
          .top-index {
            display: block;
            margin-top: 20px;
            padding-left: 30px;
            font-size: 20px;
            font-weight: bold;
            border-bottom: 1px solid $care-text-color;
          }
          .caption {
            margin: 5px;
            padding: 5px;
            border: 1px solid $care-text-color;
            border-radius: 5px;
            font-size: 14px;
          }
          ul.sub-menu {
            margin: 0;
            padding: 0;
            list-style-type: none;
            margin-block-start: 0;
            margin-inline-start: 0;
            &.narrative-signin {
              text-align: center;
              li {
                display: inline-block;
                margin: 5px 0;
                width: auto;
                border: 1px solid $care-text-color;
                background-color: #fff;
              }
            }
            li {
              margin-left: 30px;
              &.index {
                margin-top: 10px;
                margin-left: 10px;
              }
              &.colmn-style {
                display: flex;
                justify-content: space-evenly;
              }
              a {
                display: inline-block;
                width: 100%;
                height: 100%;
                padding: 5px 10px;
                text-decoration: none;
                color: $care-text-color;
                &.image-link {
                  display: inline-flex;
                  align-items: center;
                }
                .file-style {
                  display: inline-block;
                  margin-right: 5px;
                  padding: 3px;
                  background-color: $care-main-color;
                  color: #fff;
                  font-size: 10px;
                  font-weight: bold;
                }
                .jump-mark {
                  margin-left: 5px;
                  color: $care-main-color;
                }
                .video-jump-mark {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 5px;
                  width: 20px;
                  height: 15px;
                  font-size: 10px;
                  text-align: center;
                  border-radius: 2px;
                  background-color: $care-main-color;
                  color: #fff;
                }
                img {
                  &.google-badge {
                    height: 62px;
                  }
                  &.apple-badge {
                    padding: 10px;
                    height: 42px;
                  }
                  &.nb-icon {
                    margin-right: 5px;
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include mediaQuery('phone') {
    main {
      background-image: none;
    }
    section.hero {
      .hero-title {
        height: 150px;
        background-image: url('~@/assets/css/images/care_hero_img.png');
        background-size: cover;
        h1 {
          display: inline-block;
          position: absolute;
          top: 32px;
          left: 10px;
          img {
            width: 230px;
          }
        }
      }
      .hero-caption {
        padding: 10px;
        font-size: 16px;
        line-height: 28px;
      }
    }
    section.what-care-nb-akita {
      padding: 0 10px;
      .content {
        padding: 10px;
        font-size: 18px;
        line-height: 33px;
        h2 {
          img {
            width: 100%;
          }
        }
      }
      .figure {
        padding: 30px 0;
        img {
          width: 100%;
        }
      }
    }
    section {
      padding: 0 10px;
      .content {
        padding: 10px;
        h2 {
          text-align: center;
        }
        .for-akita {
          h3 {
            font-size: 18px;
            letter-spacing: 0.1em;
          }
        }
      }
    }
    section.others {
      padding: 0 10px;
      .content {
        margin: 10px 0;
        padding: 20px 10px;
        ul.content-list {
          li {
            ul.sub-menu {
              li {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

</style>
